import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { UploadAvatarCodec } from 'app/codecs'
import { useCreateTaskContext } from 'app/create-task'
import { CloseIcon } from 'assets/SVGComponents'
import { Button, FileDropzone, Input, Modal } from 'components'
import { FormInput, textFieldError } from 'components/Form'
import { Select } from 'components/Select'
import * as c from 'io-ts'
import { concatQueryParams } from 'lib/request'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { Fragment, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TagCodecArray } from '../../app/codecs/tag'
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/drop-down.svg'

type Props = {
  onClose: () => void
  open: boolean
  refetch?: () => void
}

type Translation = { label: string; value: string }

type TypeFormValues = {
  language: string
  file: null | File
  tags: string[]
  fileType: string
  thumbnail?: File | null
  filename: string
  translations: Translation[]
}

export const UploadVideoModal = ({ onClose, open }: Props) => {
  const defaultValues: TypeFormValues = {
    language: '',
    file: null,
    tags: [],
    fileType: 'file',
    thumbnail: null,
    filename: '',
    translations: [],
  }
  const { handleSubmit, setValue, watch, reset, resetField, control } = useForm(
    {
      defaultValues,
    },
  )
  const formFields = watch()
  const inputValueRef = useRef<HTMLInputElement | null>(null)
  const { onCreateTask, isLoading } = useCreateTaskContext()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const $uploadThumbnail = useMutation(
    'POST',
    '/api/files/image?type=THUMBNAIL',
    UploadAvatarCodec,
    false,
    { type: 'file' },
  )

  const onSubmit = (data: any) => {
    const { tags, thumbnail: file, filename, translations, ...rest } = data
    const mappedTranslations = translations.map(
      (translation: Translation) => translation.value,
    )

    if (file) {
      $uploadThumbnail.mutate(
        {
          body: { file },
        },
        {
          onSuccess: ({ fileId: thumbnailId }) => {
            onCreateTask({
              ...rest,
              thumbnailId,
              tags,
              translations: mappedTranslations,
            })
          },
          onError: error => {
            if (error.type === 'client_error') {
              enqueueSnackbar(t(`error_notification.${error.code}`), {
                variant: 'error',
              })
            }

            if (error.type === 'server_error') {
              enqueueSnackbar(t(`server_error.${error.status}`), {
                variant: 'error',
              })
            }
          },
        },
      )
    } else {
      onCreateTask({
        ...rest,
        tags,
        filename,
        translations: mappedTranslations,
      })
    }
    reset()
    onClose()
  }

  const $languages = useJsonQuery(
    'GET',
    '/api/tasks/languages',
    c.record(c.string, c.string),
  )

  const params = useMemo(() => {
    const params = new URLSearchParams()

    if (formFields.language) {
      params.set('transcriptionLanguage', formFields.language)
    }

    return params
  }, [formFields.language])

  const $translations = useJsonQuery(
    'GET',
    concatQueryParams('/api/tasks/languages', params),
    c.record(c.string, c.string),
  )

  const $tags = useJsonQuery('GET', '/api/organizations/tags', TagCodecArray)

  if (!$languages.data || !$tags.data) {
    return null
  }

  const tagsData = $tags.data

  const languageOptions = Object.keys($languages.data).map(key => ({
    label: $languages.data[key],
    value: key,
  }))

  const translationOptions =
    $translations.data && formFields.language !== ''
      ? Object.keys($translations.data).map(key => ({
          label: $translations.data[key],
          value: key,
        }))
      : []

  const isDropzoneDisabled = !formFields.language

  const isSubmitDisabled =
    isDropzoneDisabled ||
    !formFields.file ||
    $uploadThumbnail.isLoading ||
    isLoading

  return (
    <Modal open={open} onClose={onClose}>
      <Box p={3} width="446px">
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2} sx={{}}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {t('upload_file.add_video')}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {t('upload_file.source_language')}
              </Typography>
              <Controller
                control={control}
                name="language"
                rules={{ required: t('validation.language_required') }}
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    {...textFieldError(fieldState.error)}
                    options={languageOptions}
                    onChange={value => {
                      resetField('translations')
                      field.onChange(value)
                    }}
                    disabled={isLoading}
                    sx={{ mb: 0 }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">
                {t('upload_file.optionally_translate_to')}
              </Typography>
              <Controller
                control={control}
                name="translations"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    clearOnBlur
                    loading={$translations.isLoading}
                    options={translationOptions}
                    onChange={(_e, value) => field.onChange(value)}
                    disabled={
                      translationOptions.length === 0 ||
                      field.value.length === 5
                    }
                    popupIcon={<ArrowDownIcon />}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    sx={{
                      '.MuiInputBase-root': { paddingBottom: 0 },
                      '.MuiAutocomplete-popupIndicator': { marginRight: '5px' },
                    }}
                    getOptionLabel={option => {
                      return typeof option === 'string' ? option : option.label
                    }}
                    limitTags={translationOptions.length > 1 ? 5 : 1}
                    renderTags={(options, getTagProps) =>
                      options.map((option, index) =>
                        translationOptions.length > 1 ? (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.value + index}
                            label={option.label}
                            size="small"
                            disabled={false}
                            deleteIcon={
                              <Box
                                sx={{
                                  marginTop: '7px !important',
                                  marginRight: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                <CloseIcon background={'#19445B'} />
                              </Box>
                            }
                            sx={theme => ({
                              background: theme.palette.primary.dark,
                              color: 'white',
                              fontWeight: 400,
                            })}
                          />
                        ) : (
                          <Fragment key={option.value}>{option.label}</Fragment>
                        ),
                      )
                    }
                    renderInput={params => {
                      const { InputLabelProps, InputProps, ...rest } = params
                      return (
                        <Input
                          inputRef={inputValueRef}
                          placeholder={
                            field.value.length === 0
                              ? t('upload_file.select_languages')
                              : undefined
                          }
                          {...InputProps}
                          {...rest}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                label={t('upload_file.file_name')}
                control={control}
                name="filename"
                required
                fullWidth
                maxLength={100}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">{t('upload_file.tags')}</Typography>
              <Controller
                control={control}
                name="tags"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    freeSolo
                    clearOnBlur
                    options={tagsData}
                    onChange={(_e, value) => field.onChange(value)}
                    sx={{ '.MuiInputBase-root': { paddingBottom: 0 } }}
                    renderTags={(options, getTagProps) =>
                      options.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={option + index}
                          label={option}
                          size="small"
                          deleteIcon={
                            <Box
                              sx={{
                                marginTop: '7px !important',
                                marginRight: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              <CloseIcon background={'#19445B'} />
                            </Box>
                          }
                          sx={theme => ({
                            background: theme.palette.primary.dark,
                            color: 'white',
                            fontWeight: 400,
                          })}
                        />
                      ))
                    }
                    renderInput={params => {
                      const { InputLabelProps, InputProps, ...rest } = params
                      return (
                        <Input
                          inputRef={inputValueRef}
                          multiline
                          {...InputProps}
                          {...rest}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Controller
            control={control}
            name="file"
            rules={{ required: t('validation.field_is_required') }}
            render={({ field: { onChange } }) => (
              <FileDropzone
                isLoading={isLoading}
                accept={{
                  'audio/*': ['.aac', '.amr', '.flac', '.mp3', '.m4a'],
                  'video/*': ['.mp4', '.mov', '.mpeg', '.mpg', '.ogg'],
                }}
                disabled={isDropzoneDisabled}
                onChange={({ file }) => {
                  onChange(file)
                  setValue('filename', file?.name ?? '', { shouldDirty: true })
                }}
                onThumbnailChange={file =>
                  setValue('thumbnail', file, { shouldDirty: true })
                }
              />
            )}
          />

          <Stack direction="row" justifyContent="space-between" mt={3}>
            <Button
              onClick={onClose}
              sx={{
                borderRadius: '43px',
                fontWeight: 'bold',
                borderColor: '#CED7DE',
                textTransform: 'capitalize',
              }}
              variant="outlined"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              withShadow
              type="submit"
              sx={{ textTransform: 'capitalize' }}
              variant="contained"
              disabled={isSubmitDisabled}
              {...(isLoading
                ? {
                    endIcon: <CircularProgress size="small" color="inherit" />,
                  }
                : {})}
            >
              {t('buttons.save')}
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  )
}
