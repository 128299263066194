import { useQueryClient } from '@tanstack/react-query'
import { SessionCodec, TMediaAdd, TUploadTokenAdd } from 'app/codecs'
import constate from 'constate'
import * as t from 'io-ts'
import Uploader from 'lib/multipart-upload'
import { definePartsNumber } from 'lib/multipart-upload/define-upload-parts-number'
import { useJsonQuery } from 'lib/rest-query'
import { useMutation } from 'lib/rest-query/rest-mutation'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { useAuthContext } from './auth'

const TFileUploadResult = t.type({
  fileId: t.string,
})

const REACT_APP_KALTURA_SERVICE = 'https://www.kaltura.com/api_v3/service/'

type TrackProgress = {
  sent: number
  total: number
  percentage: number
  filename: string
  trackId: string
  abort: () => void
}
type TrackList = TrackProgress[]

const useCreateTask = () => {
  const $createTask = useMutation('POST', '/api/tasks')
  const [trackProgress, setTrackProgress] = useState<TrackList | null>(null)
  const { accessToken } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const query = useQueryClient()
  const { t } = useTranslation()
  const $uploadFile = useMutation(
    'POST',
    '/api/files/media',
    TFileUploadResult,
    false,
    {
      type: 'file',
    },
  )

  const $mediaAddKaltura = useMutation(
    'POST',
    `${REACT_APP_KALTURA_SERVICE}media/action/add`,
    TMediaAdd,
    true,
  )

  const $mediaAddContentKaltura = useMutation(
    'POST',
    `${REACT_APP_KALTURA_SERVICE}media/action/addContent`,
    TMediaAdd,
    true,
  )

  const $kalturaSession = useJsonQuery(
    'GET',
    '/api/integrations/kaltura/session',
    SessionCodec,
    {
      options: {
        keepPreviousData: true,
        // No need to create current request until uploading to Kaltura from our service will be enabled
        enabled: false,
      },
    },
  )

  const $uploadTokenAddKaltura = useMutation(
    'POST',
    `${REACT_APP_KALTURA_SERVICE}uploadtoken/action/add`,
    TUploadTokenAdd,
    true,
  )

  const $uploadTokenUploadKaltura = useMutation(
    'POST',
    `${REACT_APP_KALTURA_SERVICE}uploadtoken/action/upload`,
    TUploadTokenAdd,
    true,
    { type: 'file' },
  )

  const { data } = $kalturaSession

  const mutateUploadFile = $uploadFile.mutate
  const mutateCreateTask = $createTask.mutate
  const mutateMediaAdd = $mediaAddKaltura.mutate
  const mutateAddContent = $mediaAddContentKaltura.mutate
  const mutateUploadTokenAdd = $uploadTokenAddKaltura.mutate
  const mutateUploadTokenUpload = $uploadTokenUploadKaltura.mutate

  const isLoadingUploadFile = $uploadFile.isLoading
  const isCreateTaskLoading = $createTask.isLoading
  const isKalturaLoading = $mediaAddKaltura.isLoading

  const isLoading =
    isLoadingUploadFile || isCreateTaskLoading || isKalturaLoading

  const onCreateTask = useCallback(
    ({
      file,
      filename,
      language,
      tags,
      fileType,
      thumbnailId,
      translations,
    }: {
      file: File | null
      filename: string
      language: string
      fileType: string
      tags: string[]
      translations: string[]
      thumbnailId?: File
    }) => {
      if (fileType === 'kaltura' && data) {
        const search = new URLSearchParams()
        search.set('ks', data.session)
        search.set('format', '1')

        mutateUploadTokenAdd(
          {
            search,
          },
          {
            onSuccess: res => {
              const token = res.id
              mutateUploadTokenUpload(
                {
                  search,
                  body: {
                    uploadTokenId: res.id,
                    fileData: file,
                    resume: false,
                    finalChunk: true,
                    resumeAt: -1,
                  },
                },
                {
                  onSuccess: res => {
                    mutateMediaAdd(
                      {
                        search,
                        body: {
                          entry: {
                            objectType: 'KalturaMediaEntry',
                            mediaType: 1,
                            tags,
                            name: file && file.name,
                          },
                        },
                      },
                      {
                        onSuccess: res => {
                          mutateAddContent(
                            {
                              search,
                              body: {
                                entryId: res.rootEntryId,
                                resource: {
                                  objectType:
                                    'KalturaUploadedFileTokenResource',
                                  token: token,
                                },
                              },
                            },
                            {
                              onSuccess: res => {
                                mutateCreateTask(
                                  {
                                    body: {
                                      mediaId: res.id,
                                      languageCode: language,
                                      integrationType: 'kaltura',
                                      tags,
                                      thumbnailId,
                                    },
                                  },
                                  {
                                    onSuccess: () => {
                                      setTimeout(() => {
                                        query.invalidateQueries()
                                      }, 1000)
                                      enqueueSnackbar(
                                        t(
                                          'success_notification.file_is_starting_to_process',
                                        ),
                                        {
                                          variant: 'success',
                                        },
                                      )
                                    },
                                    onError: error => {
                                      if (error.type === 'client_error') {
                                        enqueueSnackbar(
                                          t(`error_notification.${error.code}`),
                                          {
                                            variant: 'error',
                                          },
                                        )
                                      }

                                      if (error.type === 'server_error') {
                                        enqueueSnackbar(
                                          t(`server_error.${error.status}`),
                                          {
                                            variant: 'error',
                                          },
                                        )
                                      }
                                    },
                                  },
                                )
                              },
                            },
                          )
                        },
                      },
                    )
                  },
                },
              )
            },
          },
        )
        return
      }
      // Files over 5mb are handled by multipart uploader
      const MB = 1024 * 1024

      if (file && file.size > 5 * MB) {
        const trackId = uuid()
        const videoUploaderOptions = {
          fileName: filename,
          file: file,
          accessToken: accessToken?.accessToken,
          numberOfParts: definePartsNumber(file.size),
          chunkSize: file.size / definePartsNumber(file.size) + MB,
          language,
          tags,
          translationLanguageCodes: translations,
          thumbnailId,
        }
        const uploader = new Uploader(videoUploaderOptions)
        let currentPercentage: undefined | number
        const onCancel = () => {
          if (uploader) {
            uploader.abort()
            setTrackProgress(null)
            enqueueSnackbar(t('error_notification.error_upload_canceled'), {
              variant: 'error',
            })
          }
        }
        uploader
          .onProgress(({ percentage, total, sent }: TrackProgress) => {
            // to avoid the same result to be logged twice
            if (percentage !== currentPercentage) {
              currentPercentage = percentage
              if (currentPercentage === 100) {
                setTimeout(() => {
                  query.invalidateQueries()
                }, 1000)
                enqueueSnackbar(
                  t('success_notification.file_is_starting_to_process'),
                  {
                    variant: 'success',
                  },
                )
              }
            }

            setTrackProgress(prevState => {
              if (prevState) {
                const present = prevState.find(
                  uploadingFile => uploadingFile.trackId === trackId,
                )
                if (present) {
                  const newValues = prevState.map(track =>
                    track.trackId === trackId
                      ? {
                          percentage: currentPercentage || 0,
                          total,
                          sent,
                          filename,
                          trackId,
                          abort: onCancel,
                        }
                      : track,
                  )

                  return newValues
                }
                return [
                  ...prevState,
                  {
                    percentage: currentPercentage || 0,
                    total,
                    sent,
                    filename,
                    trackId,
                    abort: onCancel,
                  },
                ]
              }
              return [
                {
                  percentage: currentPercentage || 0,
                  total,
                  sent,
                  filename,
                  trackId,
                  abort: onCancel,
                },
              ]
            })
          })
          .onError((error: any) => {
            if (error.type === 'client_error' && error.code) {
              enqueueSnackbar(t(`error_notification.${error.code}`), {
                variant: 'error',
              })
            } else {
              enqueueSnackbar(
                t('error_notification.error_unable_to_upload_file'),
                {
                  variant: 'error',
                },
              )
            }
          })

        uploader.start()

        return
      }
      mutateUploadFile(
        {
          body: {
            media: file,
            filename,
          },
        },
        {
          onSuccess: ({ fileId }) => {
            mutateCreateTask(
              {
                body: {
                  mediaId: fileId,
                  languageCode: language,
                  translationLanguageCodes: translations,
                  tags,
                  thumbnailId,
                },
              },
              {
                onSuccess: () => {
                  setTimeout(() => {
                    query.invalidateQueries()
                  }, 1000)
                  enqueueSnackbar(
                    t('success_notification.file_is_starting_to_process'),
                    {
                      variant: 'success',
                    },
                  )
                },
                onError: error => {
                  if (error.type === 'client_error') {
                    enqueueSnackbar(t(`error_notification.${error.code}`), {
                      variant: 'error',
                    })
                  }

                  if (error.type === 'server_error') {
                    enqueueSnackbar(t(`server_error.${error.status}`), {
                      variant: 'error',
                    })
                  }
                },
              },
            )
          },
          onError: error => {
            if (error.type === 'client_error') {
              enqueueSnackbar(t(`error_notification.${error.code}`), {
                variant: 'error',
              })
            }

            if (error.type === 'server_error') {
              enqueueSnackbar(t(`server_error.${error.status}`), {
                variant: 'error',
              })
            }
          },
        },
      )
    },
    [
      data,
      mutateUploadFile,
      mutateUploadTokenAdd,
      mutateUploadTokenUpload,
      mutateMediaAdd,
      mutateAddContent,
      mutateCreateTask,
      enqueueSnackbar,
      t,
      query,
      accessToken?.accessToken,
    ],
  )

  return { isLoading, onCreateTask, trackProgress }
}

export const [CreateTaskProvider, useCreateTaskContext] =
  constate(useCreateTask)
