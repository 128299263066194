import {
  useMutation as useReactMutation,
  UseMutationOptions,
} from '@tanstack/react-query'
import { useAuthContext } from 'app/auth'
import { isLeft } from 'fp-ts/Either'
import { Mixed, TypeOf } from 'io-ts'
import { formatValidationErrors } from 'io-ts-reporters'
import { generatePath } from 'react-router-dom'

import {
  concatQueryParams,
  ExtractRouteParams,
  MutationError,
  RestMethod,
  throwError,
} from './common'

type Body = { [key: string]: any }
type MutationType = 'json' | 'file'

const createBody = (
  body: Body | undefined,
  type: MutationType,
): string | FormData | undefined => {
  if (body === undefined) {
    return undefined
  }

  if (type === 'json') {
    return JSON.stringify(body)
  }

  if (type === 'file') {
    const formData = new FormData()

    for (const [key, value] of Object.entries(body)) {
      if (Array.isArray(value)) {
        for (const file of value) formData.append(key, file)
      } else {
        formData.append(key, value)
      }
    }

    return formData
  }
}

type Input<Path extends string> = keyof ExtractRouteParams<Path> extends never
  ? {
      params?: undefined
      body?: Body
      search?: URLSearchParams
    }
  : {
      params: ExtractRouteParams<Path>
      languageCode?: string
      body?: Body
      search?: URLSearchParams
    }

export const useMutation = <
  Path extends string,
  C extends Mixed | undefined = undefined,
>(
  method: RestMethod,
  url: Path,
  codec?: C,
  withAuthorization?: Boolean,
  {
    type = 'json',
    options,
  }: {
    type?: MutationType
    options?: UseMutationOptions<
      C extends Mixed ? TypeOf<C> : null,
      MutationError,
      Input<Path>,
      unknown
    >
  } = {},
) => {
  const { accessToken, signOut } = useAuthContext()
  const token = accessToken === null ? null : accessToken.accessToken

  return useReactMutation<
    C extends Mixed ? TypeOf<C> : null,
    MutationError,
    Input<Path>
  >(async ({ params, body, search }) => {
    if (accessToken && accessToken.exp * 1000 < Date.now()) {
      return signOut()
    }
    const headers = new Headers()

    if (type === 'json') {
      headers.set('Content-Type', 'application/json')
    }

    if (token && !withAuthorization) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    const fullUrl = concatQueryParams(generatePath(url, params), search)

    try {
      const response = await window.fetch(fullUrl, {
        method,
        body: createBody(body, type),
        headers,
      })

      if (response.ok) {
        try {
          if (codec === undefined) {
            return null
          }

          const json: unknown = await response.json()
          const decodedJson = codec.decode(json)

          if (isLeft(decodedJson)) {
            const errors = formatValidationErrors(decodedJson.left)
            return throwError({ type: 'failed_to_decode_json', json, errors })
          }
          return decodedJson.right
        } catch (error: any) {
          if (error.type === undefined) {
            throwError({
              type: 'failed_to_parse_json',
              response: response,
              error,
            })
          }

          throw error
        }
      }

      if (response.status >= 500) {
        throwError({ type: 'server_error', status: response.status })
      }

      if (response.status === 404) {
        const json = await response.json()
        if (json.code === 'upload_is_disabled') {
          throwError({
            type: 'client_error',
            message: json.message,
            code: json.code,
          })
        }
        throwError({
          type: 'not_found',
          message: json.message,
          code: json.code,
        })
      }

      if (response.status >= 400) {
        const json = await response.json()

        throwError({
          type: 'client_error',
          message: json.message,
          code: json.code,
        })
      }
    } catch (error: any) {
      if (error.type === undefined) {
        throwError({ type: 'network_error', error })
      }

      throw error
    }
  }, options)
}
